import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Section from './components/Section';
import Footer from './components/Footer';
import './App.css';
import TrademarkDisclaimer from "./components/TrademarkDisclaimer";

const AppContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.7) 40%,
          rgba(0, 0, 0, 0.01) 100%
  ), url('/dalle-astro-2.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  color: #fff;
  padding: 40px;

  @media (max-width: 768px) {
    background: linear-gradient(
            rgba(0, 0, 0, 0.7) 60%,
            rgba(0, 0, 0, 0.4) 100%
    ), url('/dalle-astro-2.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  * {
    position: relative;
    z-index: 1;
  }
`;

const App: React.FC = () => {
    const isDomainRobodev = window.location.hostname.includes('robodev.ai') ;

    return (
        <AppContainer>
            <Header
                name="Ricardo Gonzalez"
                title={(
                    <div>Co-founder of {' '}
                    <a
                        href={'www.neutrinoapp.com'}
                        target={"_blank"}
                        style={{textDecoration: 'none', color: 'white'}}
                    >
                        Neutrino AI
                    </a>
                    </div>
                )}
            />
            <Section
                title="[About]"
                content={(<div>
                    I'm a co-founder and CEO of Neutrino AI, where we are building the infrastructure to power multi-model AI systems. We are backed by Pear VC.
                    <br/>
                    I graduated from Penn, where I studied CS and business at Wharton. I enjoy coding and have special interests in AI for use cases outside of language, like music and robotics.
                    <br/>
                    <br/>
                    I'm currently also building <a style={{color: "#26a7de"}} href={"https://github.com/ricardo-agz/metro"} target={"_blank"}>Metro</a>, the first web framework designed to be written by an LLM, not a human. The design choices favor opinionated conventions that serve as guardrails and remain intuitive for LLMs (naturally, this makes it intuitive for human developers as well).
                </div>
                )}
            />
            <Section
                title="[Previously]"
                content={(<div>
                    I previously worked at JP Morgan in quant research, taught CIS-1960 (Ruby on Rails Web Development), and was a student NLP researcher at Penn.
                    <br/>
                    I also built <a style={{color: "#26a7de"}} href={"https://github.com/ricardo-agz/robodev-ai"} target="_blank">Robodev AI</a> as my senior research project in 2022-2023, it was a software engineering agent that could code an entire app's backend from an app description.
                </div>)}
            />
            <Section title="[Interests]" content={`I enjoy running, cycling, and playing music.`} />
            <Section title="[Contact]" content={`{my first name}@neutrinoapp.com`} />
            <Footer
                links={[
                    { label: 'X', url: 'https://x.com/ricardo_agzz' },
                    { label: 'LinkedIn', url: 'https://www.linkedin.com/in/ricardo-agm/' },
                    { label: 'GitHub', url: 'https://github.com/ricardo-agz' },
                ]}
            />
            <TrademarkDisclaimer />
        </AppContainer>
    );
};

export default App;
