import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  margin-top: 40px;
`;

const TrademarkDisclaimer = () => {
    const isDomainRobodev = window.location.hostname.includes('robodev.ai');
    const searchParams = new URLSearchParams(window.location.search);
    const isRobodevQuery = searchParams.get('robodev') === 'true';

    if (!isDomainRobodev && !isRobodevQuery) {
        return null;
    }

    return (
        <FooterContainer>
            *This site is my personal website and is unaffiliated with any industrial robotics company or trademark holder.
        </FooterContainer>
    );
};

export default TrademarkDisclaimer;
